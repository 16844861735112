import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import { useInView } from 'react-intersection-observer'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface ReduceWasteProps {
  data: {
    title: string
    image: {
      imageId: string
    }
    videoFileUrl?: string
    iconText: Array<{
      text: string
      svg: string
    }>
  }
}

const ReduceBlock = ({ svg, text }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true
  })

  return (
    <div className={cx('container--400 mxa animate-text',  inView && 'animated')} ref={ref}>
      <div className='row x'>
        <div className='col c4 c14--800 reduce__icon'>
          <div dangerouslySetInnerHTML={{ __html: svg}} />
        </div>
        <div className='col c10'>
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}

export const ReduceWaste = ({ data }: ReduceWasteProps) => {
  const {
    title,
    image,
    ctaLink,
    iconText,
    videoFileUrl
  } = data
  
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
  })

  return (
    <div className='mt4 mb5' ref={ref}>
      <div className='container--xl outer mxa p2 tc  x'>
        <h2>{title}</h2>
      </div>
      <div className='row df bt bc bb fw jcb gutter--none'>
        <div className='col c14 ca4--1000  order--1-1000'>
          <div className='df y jcb fdc'>
            <div className='tl tc--800 x y df bb bc jcc aic p2 p4--800'>
              <ReduceBlock svg={iconText[0].svg} text={iconText[0].text} />
            </div>
            <div className='tl tc--800 x bb bc bbn--1000 y df jcc aic p2 p4--800'>
              <ReduceBlock svg={iconText[2].svg} text={iconText[2].text} />
            </div>
          </div>
        </div>
        <div className='col bl--1000 br--1000 bc ca4--1000 order--2 order--1-1000'>
          <div className='df jcb fdc'>
            <div className='p2 p4--800'>
              <div className='pr x'>
                {videoFileUrl && inView && (
                  <video muted={true} autoPlay={true} loop={true} playsInline={true} className='pa z1 top left x y object-fit'>
                    <source src={videoFileUrl} />
                  </video>
                )}
                <Image imageId={image.imageId} />
              </div>
            </div>
            <div>
              <Link to={`/${ctaLink.slug}`} className='button bln brn bbn x db'>
                <span>Learn More About Us</span>  
              </Link>
            </div>
          </div>
        </div>
        <div className='col c14 ca4--1000  order--1-1000 '>
          <div className='df y jcb fdc'>
            <div className='tl tc--800 x y df bb bc jcc aic p2 p4--800'>
              <ReduceBlock svg={iconText[1].svg} text={iconText[1].text} />
            </div>
            <div className='tl tc--800 x y df jcc aic p2 p4--800'>
              <ReduceBlock svg={iconText[3].svg} text={iconText[3].text} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReduceWaste